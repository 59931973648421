
import Vue from 'vue';
import { LinkedType } from '../../store/modules/agencyadmin/types';

export default Vue.extend({
  props: ['type'],
  computed: {
    enabledIcon(): string {
      const type = this.type as LinkedType;
      return type.Enabled ? 'check_circle' : 'error';
    },
    enabledClass(): string {
      const type = this.type as LinkedType;
      return type.Enabled ? 'green--text' : 'red--text';
    },
    linkedIcon(): string {
      return this.isLinked ? 'check_circle' : 'error';
    },
    linkedClass(): string {
      return this.isLinked ? 'green--text' : 'red--text';
    },
    isLinked(): boolean {
      const type = this.type as LinkedType;
      return type.Linked;
    },
  },
});
